import React from "react";
import { graphql } from "gatsby";
import Alltranslations from "../components/AlltranslationsComp";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TranslationComponent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Alltranslations />
      <CookieConsent
        location="bottom"
        buttonText={t("Accept Cookies")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("This website uses cookies to enhance the user experience.")}
      </CookieConsent>
    </>
  );
};

export default TranslationComponent;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        featuredPostCount
        postPerPage
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { published: { ne: false } }
        fileAbsolutePath: { regex: "/content/posts/" }
      }
    ) {
      nodes {
        ...PostQueryFragment
      }
    }
  }
`;
